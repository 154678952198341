import { inspect } from 'util';

import orga from 'orga-unified';
import reorg2rehype from 'reorg-rehype';
import html from 'rehype-stringify';
import visit from 'unist-util-visit';
import h from 'hastscript';
import u from 'unist-builder';

export async function orgToHtml(content) {

    const processor = orga()
          //.use(printAst)
          .use(reorg2rehype, { highlight: false })
          .use(changeClassesTransformer) // may not needed any more
          .use(removeTitleTransformer)
          .use(removeSectionDivsTransformer)
          .use(textToLinkTransformer)
          .use(html);

    return new Promise((resolve, reject) => {
        processor.process(content, (err, file) => {
            if (err) {
                reject(err);
                return;
            }
            resolve(file.contents);
        });
    });
}

function printAst() {
    return function transformer(ast) {
        console.log(inspect(ast.children[0].children[1]));
    };
}
function removeTitleTransformer() {
    return function transformer(ast) {
        visit(ast, (node) => node.tagName === 'h1', (node, index, parent) => {
            parent.children.splice(index, 1);
            return visit.EXIT;
        });
    };
};

function removeSectionDivsTransformer() {
    return function transformer(ast) {
        visit(ast, (node) => node.type === 'element' && node.tagName === 'div', (node, index, parent) => {
            parent.children.splice(index, 1, ...node.children);
            return [visit.SKIP, index];
        });
    };
}

function changeClassesTransformer() {
    return function transformer(ast) {
        changeClass(ast);
        if (ast && ast.children) {
            ast.children.forEach(transformer);
        }
    };

    function changeClass(node) {
        if (node && node.properties && node.properties.className) {
            node.properties.className = node.properties.className.reduce((acc, className) => {acc.push(`note-${className}`); return acc;}, []);
        }
    }
};

// From http://urlregex.com/
const linkre = /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[\-;:&=\+\$,\w]+@)?[A-Za-z0-9\.\-]+|(?:www\.|[\-;:&=\+\$,\w]+@)[A-Za-z0-9\.\-]+)((?:\/[\+~%\/\.\w\-_]*)?\??(?:[\-\+=&;%@\.\w_]*)#?(?:[\.\!\/\\\w]*))?)/g;

function textToLinkTransformer() {
    return function transformer(ast) {
        visit(ast, 'text', linkTextToHref);
    };

    function linkTextToHref(node, index, parent) {
        const matches = Array.from(node.value.matchAll(linkre));
        if (matches.length === 0) {
            return [visit.CONTINUE];
        }

        const newNodes = [];
        let start = 0;
        for (let match of matches) {
            const before = node.value.slice(start, match.index);
            const href = match[0];
            if (before.length > 0) {
                newNodes.push({type: 'text', value: before});
            }
            newNodes.push(h('a', { href }, [href]));
            start = match.index + href.length;
        }

        if (start < node.value) {
            const remaining = node.value.slice(start);
            newNodes.push({type: 'text', value: remaining});
        }

        parent.children.splice(index, 1, ...newNodes);
        return [visit.SKIP, index + newNodes.length];
    }
}
