"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.isWaitingForCode = exports.isAuthenticated = exports.dbxExchangeCode = exports.dbxAuthenticate = exports.RemoteStorage = void 0;
var Dropbox = __importStar(require("dropbox"));
var owl_1 = require("owl");
//require('isomorphic-fetch');
// There are several types of things we might get from Dropbox or any remote place we are storing
// data:
// 1) Notes
// 2) Note attachment directories (not sure this is important)
// 3) Note attachments
// 4) Auxiliary data, such as full text search indexes and configuration.
//
// I'm starting to think that at this level of abstraction, we should just be thinking of
// things as files and folders, not as anything more specialized than that. Dropbox, or anything
// sync tech doesn't care about notes or attachments.
/**
 *
 */
var RemoteStorage = /** @class */ (function () {
    function RemoteStorage(localStorage, fetch) {
        // TODO change how this works, this is just for a little test. This should probably be passed in
        // as an option to listFiles.
        this.listFilesType = 'full';
        this.localStorage = localStorage;
        this.fetch = fetch;
        this.dbx = this.getDbx();
    }
    RemoteStorage.prototype.refreshAuth = function () {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.dbx.auth.checkAndRefreshAccessToken()];
                    case 1:
                        response = _a.sent();
                        console.log(response);
                        if (response) {
                            this.localStorage.setItem('at', this.dbx.auth.getAccessToken());
                            this.localStorage.setItem('atea', this.dbx.auth.getAccessTokenExpiresAt().valueOf());
                            console.log("Set access token to '" + this.localStorage.getItem('at') + "'");
                            // this.localStorage.setItem('rt', response.result.refresh_token);
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    RemoteStorage.prototype.listChangedFiles = function () {
        var _a;
        return __awaiter(this, void 0, void 0, function () {
            var cursor, response, fileMetadata;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        cursor = this.localStorage.getItem('cursor');
                        if (!cursor) {
                            // We don't have a cursor from the list time we listed files, something is wrong.
                            return [2 /*return*/, []];
                        }
                        fileMetadata = [];
                        _b.label = 1;
                    case 1: return [4 /*yield*/, this.dbx.filesListFolderContinue({ cursor: cursor })];
                    case 2:
                        response = _b.sent();
                        if ((_a = response === null || response === void 0 ? void 0 : response.result) === null || _a === void 0 ? void 0 : _a.entries) {
                            console.log("processing " + response.result.entries.length + " files...");
                            response.result.entries.forEach(function (entry) {
                                if (entry['.tag'] === 'file') {
                                    fileMetadata.push(new owl_1.FileMetadata(entry.name, entry.path_lower, new Date(entry.server_modified), new Date(entry.client_modified), entry.rev, entry.size));
                                }
                                // todo if (entry['.tag'] === 'deleted')
                            });
                        }
                        else {
                            console.warn("No results returned from dropbox.");
                        }
                        _b.label = 3;
                    case 3:
                        if (response.result.has_more) return [3 /*break*/, 1];
                        _b.label = 4;
                    case 4: return [2 /*return*/, fileMetadata];
                }
            });
        });
    };
    RemoteStorage.prototype.listFiles = function () {
        var _a;
        return __awaiter(this, void 0, void 0, function () {
            var fileMetadata, firstPass, response;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        this.saveCursor();
                        fileMetadata = [];
                        if (!(this.listFilesType === 'full')) return [3 /*break*/, 8];
                        console.log("Getting all files");
                        firstPass = true;
                        response = void 0;
                        _b.label = 1;
                    case 1:
                        if (!firstPass) return [3 /*break*/, 3];
                        return [4 /*yield*/, this.dbx.filesListFolder({
                                path: '/notes/',
                                recursive: false,
                                include_deleted: true,
                            })];
                    case 2:
                        response = _b.sent();
                        firstPass = false;
                        return [3 /*break*/, 5];
                    case 3: return [4 /*yield*/, this.dbx.filesListFolderContinue({ cursor: response.result.cursor })];
                    case 4:
                        response = _b.sent();
                        _b.label = 5;
                    case 5:
                        if ((_a = response === null || response === void 0 ? void 0 : response.result) === null || _a === void 0 ? void 0 : _a.entries) {
                            response.result.entries.forEach(function (entry) {
                                if (entry['.tag'] === 'file') {
                                    fileMetadata.push(new owl_1.FileMetadata(entry.name, entry.path_lower, new Date(entry.server_modified), new Date(entry.client_modified), entry.rev, entry.size));
                                }
                                // todo if (entry['.tag'] === 'deleted')
                            });
                        }
                        _b.label = 6;
                    case 6:
                        if (response.result.has_more) return [3 /*break*/, 1];
                        _b.label = 7;
                    case 7: return [3 /*break*/, 10];
                    case 8:
                        if (!(this.listFilesType === 'latest')) return [3 /*break*/, 10];
                        console.log("Getting latest files");
                        return [4 /*yield*/, this.listChangedFiles()];
                    case 9: return [2 /*return*/, _b.sent()];
                    case 10: return [2 /*return*/, fileMetadata];
                }
            });
        });
    };
    RemoteStorage.prototype.saveCursor = function () {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.dbx.filesListFolderGetLatestCursor({
                            path: '/notes/',
                            recursive: false,
                            include_deleted: true,
                        })];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, this.localStorage.setItem('cursor', response.result.cursor)];
                    case 2:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    RemoteStorage.prototype.getFile = function (path) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getFileAsBlob(path)];
                    case 1: return [2 /*return*/, (_a.sent()).text()];
                }
            });
        });
    };
    RemoteStorage.prototype.getFileAsBlob = function (path) {
        return __awaiter(this, void 0, void 0, function () {
            var response, contents, err_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 3, , 4]);
                        return [4 /*yield*/, this.dbx.filesDownload({ path: path })];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.result.fileBlob];
                    case 2:
                        contents = _a.sent();
                        return [2 /*return*/, contents];
                    case 3:
                        err_1 = _a.sent();
                        console.log(err_1.stack);
                        throw new Error("Unable to retrieve content for path '" + path + "'");
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    RemoteStorage.prototype.putFile = function (path, content) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                throw new Error('not implemented');
            });
        });
    };
    RemoteStorage.prototype.getStat = function (path) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                throw new Error('not implemented');
            });
        });
    };
    // Private:
    RemoteStorage.prototype.getDbx = function () {
        var dbx = new Dropbox.Dropbox({
            clientId: DROPBOX_CLIENT_ID,
            fetch: this.fetch,
            accessToken: this.localStorage.getItem('at'),
            accessTokenExpiresAt: this.localStorage.getItem('atea'),
            refreshToken: this.localStorage.getItem('rt')
        });
        return dbx;
    };
    return RemoteStorage;
}());
exports.RemoteStorage = RemoteStorage;
;
var DROPBOX_CLIENT_ID = '5an79rbopqrg2nw';
function getDbxAuth() {
    var _a, _b, _c;
    var auth = new Dropbox.DropboxAuth({
        clientId: DROPBOX_CLIENT_ID,
        fetch: window.fetch.bind(window),
        accessToken: (_a = window.localStorage.getItem('at')) !== null && _a !== void 0 ? _a : '',
        accessTokenExpiresAt: new Date((_b = window.localStorage.getItem('atea')) !== null && _b !== void 0 ? _b : ''),
        refreshToken: (_c = window.localStorage.getItem('rt')) !== null && _c !== void 0 ? _c : ''
    });
    return auth;
}
function getDbx() {
    var _a, _b, _c;
    return new Dropbox.Dropbox({
        clientId: DROPBOX_CLIENT_ID,
        fetch: window.fetch.bind(window),
        accessToken: (_a = window.localStorage.getItem('at')) !== null && _a !== void 0 ? _a : '',
        accessTokenExpiresAt: new Date((_b = window.localStorage.getItem('atea')) !== null && _b !== void 0 ? _b : ''),
        refreshToken: (_c = window.localStorage.getItem('rt')) !== null && _c !== void 0 ? _c : ''
    });
}
function getRedirectUrl() {
    var redirectUrl = new URL(window.location.toString());
    redirectUrl.pathname = '/';
    redirectUrl.hash = '';
    redirectUrl.search = '';
    return redirectUrl.toString();
}
function dbxAuthenticate() {
    return __awaiter(this, void 0, void 0, function () {
        var auth, authUrl;
        return __generator(this, function (_a) {
            auth = getDbxAuth();
            authUrl = auth.getAuthenticationUrl(getRedirectUrl(), '', 'code', 'offline', ['files.content.read'], 'user', true);
            window.localStorage.setItem('cv', auth.codeVerifier);
            window.localStorage.setItem('cc', auth.codeChallenge);
            console.log(authUrl);
            console.log("cv: " + window.localStorage.getItem('cv') + ", cc: " + window.localStorage.getItem('cc'));
            return [2 /*return*/, authUrl];
        });
    });
}
exports.dbxAuthenticate = dbxAuthenticate;
function dbxExchangeCode(code) {
    return __awaiter(this, void 0, void 0, function () {
        var auth, response, err_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    auth = getDbxAuth();
                    auth.codeVerifier = window.localStorage.getItem('cv');
                    auth.codeChallenge = window.localStorage.getItem('cc');
                    console.log("cv: " + auth.codeVerifier + ", cc: " + auth.codeChallenge);
                    window.localStorage.removeItem('cv');
                    window.localStorage.removeItem('cc');
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, auth.getAccessTokenFromCode(getRedirectUrl(), code)];
                case 2:
                    response = _a.sent();
                    console.log(response);
                    window.localStorage.setItem('at', response.result.access_token);
                    window.localStorage.setItem('atea', ((response.result.expires_in * 1000 + Date.now()).toString()));
                    window.localStorage.setItem('rt', response.result.refresh_token);
                    return [3 /*break*/, 4];
                case 3:
                    err_2 = _a.sent();
                    console.error(err_2);
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    });
}
exports.dbxExchangeCode = dbxExchangeCode;
function isAuthenticated() {
    return window.localStorage.getItem('at')
        && window.localStorage.getItem('atea')
        && window.localStorage.getItem('rt');
}
exports.isAuthenticated = isAuthenticated;
function isWaitingForCode() {
    return window.localStorage.getItem('cv')
        && window.localStorage.getItem('cc');
}
exports.isWaitingForCode = isWaitingForCode;
