import { Storage, Index, parse, FileMetadata } from 'owl';

import './main.css';
import { Elm } from './Main.elm';
import * as serviceWorker from './serviceWorker';
import { RemoteStorage, isAuthenticated, isWaitingForCode, dbxExchangeCode, dbxAuthenticate } from './dropbox';
import { LocalNotesStorage } from './database';
import 'regenerator-runtime/runtime';
import { sync, SyncOptions } from './sync';
import { orgToHtml } from './orga';

const noteFilenameRe = /^[0-9a-f]{8}-([a-f0-9]{4}-){3}[a-f0-9]{12}.org$/;

class Owl {
    constructor() {
    }

    async main() {
        this.setUpElm();

        if (isWaitingForCode()) {
            // TODO error handling
            const urlParams = new URLSearchParams(location.search);
            await dbxExchangeCode(urlParams.get('code'));
            window.location.search = '';
        }

        if (isAuthenticated()) {
            this.remoteStorage = new RemoteStorage(window.localStorage, window.fetch.bind(window));
            this.remoteStorage.refreshAuth();
            this.localStorage = new LocalNotesStorage(window.indexedDB);
            await this.localStorage.openDatabase();

            this.setUpCustomElements(this.localStorage, this.remoteStorage);

            // Load the search index
            this.index = new Index();
            const indexData = await this.localStorage.getFile('/.owl/device-fsIndex');
            if (indexData) {
                console.log(`Updating index...`);
                this.index.import(indexData.toString());
            }

            this.responsePort.send({type:'ready'});

            // Sync can happen in the background while the user is viewing notes.
            await this.sync();
        }
    }

    async sync() {
        this.responsePort.send({type:'syncProgress', percentComplete: 0});

        const lastSyncTime = window.localStorage.getItem('lastSync');
        if (!lastSyncTime) {
            // We haven't synced before, ensure a full read from remote storage
            this.remoteStorage.listFilesType = 'full';
        } else {
            // We have synced before, so just get a list of the changed files.
            this.remoteStorage.listFilesType = 'latest';
        }

        // Sync
        const syncOptions = {
            progressCallback: (percentComplete) => {
                this.responsePort.send({type:'syncProgress', percentComplete});
            },
            localfileUpdateCallback: async (metadata) => {
                try {
                    if (!metadata.name.match(noteFilenameRe)) {
                        console.log(`Not indexing ${metadata.name}, invalid note filename.`);
                        return;
                    }
                    const fileContents = await this.localStorage.getFile(metadata.path);
                    const note = parse(fileContents.toString());
                    if (note) {
                        console.log(`Indexing ${note.title}`);
                        this.index.indexNote(note);
                    } else {
                        console.log(`Not indexing ${metadata.name}, failed to parse as a note.`);
                    }
                } catch (err) {
                    console.error(`failed to index ${metadata.name}: ${err}`);
                }

                // Send the metdata to Elm for it's all notes collection?
            },
        };
        const results = await sync(this.remoteStorage, this.localStorage, syncOptions);
        window.localStorage.setItem('lastSync', new Date().toISOString());

        this.responsePort.send({type:'syncComplete'});

        const indexData = this.index.export();
        await this.localStorage.putFile('/.owl/device-fsIndex', indexData);
        console.log(`Finished updating index.`);

        // TODO write a timestamped sync log to dropbox
        let errorCount = 0;
        for (let result of results) {
            if (result.errorMessage) {
                errorCount++;
            }
        }
        if (errorCount > 0) {
            console.log(`Failed to sync ${errorCount} files.`);
        }
    }

    setUpElm() {
        let storageState = "notConfigured";
        if (isAuthenticated()) {
            storageState = "authenticated";
        } else if (isWaitingForCode()) {
            storageState = "waitingForCode";
        }

        this.app = Elm.Main.init({
            node: document.getElementById('root'),
            flags: {
                storageState
            }
        });

        if (this.app && this.app.ports && this.app.ports.jsRequest) {
            this.app.ports.jsRequest.subscribe(this.portHandler.bind(this));
            this.responsePort = this.app.ports.jsResponse;
        } else {
            console.error('Elm app ports are not set up correctly!');
        }

        // This registers a click handler after elm has rendered the page. This is a work-around
        // for the fact that for iOS to open the keyboard, .focus() must be called in direct
        // response to a user input, e.g. a button onclick handler. Using Browser.Dom.focus() in
        // the update function isn't sufficient because by the time that runs, it is no longer
        // part of the click handler.
        const interval = setInterval(() => {
            console.log('setting up click handler...');
            const button = document.getElementById('clearSearch');
            if (button) {
                button.onclick = () => {
                    document.getElementById('search').focus();
                };
                clearInterval(interval);
            }
        }, 100);
    }

    async portHandler(req) {
        console.log(`Elm requesting operation: ${JSON.stringify(req)}`);

        try {
            switch (req.cmd) {
            case 'search':
                console.log(`searching for ${req.query}`);
                const results = await this.index.searchFull(req.query);
                this.responsePort.send({type:'searchResults', results});
                break;

            case 'open':
                try {
                    const content = await this.localStorage.getFile('/notes/' + req.uuid + '.org');
                    const note = parse(content);
                    this.responsePort.send({type:'note', note});
                } catch (err) {
                    console.log(`Error loading note '${req.uuid}': ${err.stack}`);
                    this.responsePort.send({type:'note', note: {
                        title: 'Error loading note',
                        uuid: '',
                        keywords: [],
                        tags: [],
                        content: err.stack,
                    }});
                }
                break;

            case 'configureDropbox':
                const url = await dbxAuthenticate();
                window.location = url;
                break;

            case 'syncNow':
                await this.sync();
                break;

            case 'resetSync':
                this.responsePort.send({type:'syncProgress', percentComplete: 0});
                delete window.localStorage.lastSync;
                await this.localStorage.clearDatabase();
                this.index = new Index();
                await this.sync();
                break;

            default:
                console.warn(`Unsupported command: ${req.cmd}`);
            }
        } catch (err) {
            console.log(`Error executing command '${req.cmd}': ${err.stack}`);
            this.responsePort.send({type:'error', message: 'Something went wrong!'});
        }
    }

    setUpCustomElements(localStorage, remoteStorage) {
        const getFile = async (uuid) => {
            return localStorage.getFile('/notes/' + uuid + '.org');
        };

        const getImgSrc = async (img, path) => {
            try {
                const blob = await remoteStorage.getFileAsBlob(path);
                img.onload = function() {
                    window.URL.revokeObjectURL(this.src);
                };
                img.src = window.URL.createObjectURL(blob);
            } catch (err) {
                console.log(`Failed to load attachment: `, err);
            }
        };

        class Orga extends HTMLElement {
            constructor() {
                super();
            }

            connectedCallback() {}

            attributeChangedCallback() {
                const uuid = this.getAttribute('uuid') || '';
                this.generateHtml(uuid);
            }

            static get observedAttributes() {
                return ['uuid'];
            }

            async generateHtml(uuid) {
                try {
                    const content = await getFile(uuid);
                    const html = await orgToHtml(content);

                    // Shadow DOM?
                    this.innerHTML = html;

                    // Fix links so they work from Elm
                    for (const link of this.querySelectorAll('a')) {
                        // TODO this should match a note exactly, e.g. not an attachment.
                        if (link.pathname.startsWith('/notes/')) {
                            link.addEventListener('click', (event) => {
                                event.preventDefault();
                                event.stopPropagation();

                                this.dispatchEvent(new CustomEvent('linkClicked', {
                                    detail: {
                                        pathname: link.pathname,
                                        href: link.href,
                                    }
                                }));
                            });
                        }
                    }

                    // Fix img elements so they load content from dropbox
                    for (const img of this.querySelectorAll('img')) {
                        const path = decodeURI('/' + img.src.split('/').slice(3).join('/'));
                        img.src = null;
                        getImgSrc(img, path);
                    }

                } catch (err) {
                    console.log(`Error loading note in custom element: `, err);
                    this.innerHTML = `<p>Failed to load note.</p>`;
                }
            }
        }

        if (!customElements.get('org-to-html')) {
            customElements.define('org-to-html', Orga);
        } else {
            console.warn(`org-to-html element already defined.`);
        }
    }
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

const owl = new Owl();
owl.main();
