import { Storage, FileMetadata } from 'owl';
import * as path from 'path';

const DB_NAME = 'owl-notes';
const DB_VERSION = 1;

export class LocalNotesStorage {
    constructor(indexedDB) {
        if (!indexedDB) {
            console.Error('IndexedDB is required.');
            throw new Error('IndexedDB is required.');
        }
        this.indexedDB = indexedDB;
    }

    // Private
    createDatabase() {
        const notes = this.db.createObjectStore("notes", {
            autoIncrement: false,
            keyPath: 'uuid'
        });
        const fileMetadata = this.db.createObjectStore("fileMetadata", {
            autoIncrement: false, keyPath: 'path'
        });
        fileMetadata.createIndex('syncTimeIndex', 'syncTime', {
            unique: false
        });
        const files = this.db.createObjectStore("files", {
            autoIncrement: false, keyPath: 'path'
        });
    }

    async openDatabase() {
        this.db = await new Promise((resolve, reject) => {
            const request = window.indexedDB.open(DB_NAME, DB_VERSION);
            request.onerror = (event) => {
                console.error("Unable to use IndexedDB. IndexedDB is required for Owl Notes.");
                reject(event);
            };

            request.onsuccess = (event) => {
                console.log(`Got IndexedDB`);
                const db = event.target.result;

                db.onerror = (event) => {
                    console.error("Database error: " + event.target.errorCode);
                };

                db.onversionchange = (event) => {
                    // TODO something...?
                    console.log(`Database version change...`);
                };

                console.log('Resolving db');
                resolve(db);
            };

            request.onupgradeneeded = (event) => {
                console.log(`Database upgrade needed....`);
                this.db = event.target.result;

                if (event.newVersion == 1) {
                    this.createDatabase();
                }

                console.log(`Database upgrade complete.`);
            };
        });
    }

    async applyOp(op) {
        // TODO assert DB is open.
        console.log(`IndexedDB port operation requested: ${JSON.stringify(op, null, 2)}`);

        switch (op.cmd) {
        case '':
            break;
        default:
            console.warn(`Unknown/unimplemented db operation ${op.cmd} requested.`);
            throw new Error(`Unknown database operation '${op.cmd}' requested.`);
        }
    }

    async listFiles() {
        return new Promise((resolve, reject) => {
            const trxn = this.db.transaction(["fileMetadata"], "readonly");
            const fileMetadata = trxn.objectStore("fileMetadata");
            const request = fileMetadata.getAll();
            request.onerror = (err) => {
                console.error('Request to get all file metadata failed.');
                reject(err);
            };
            request.onsuccess = (event) => {
                resolve(request.result);
            };
        });
    }

    async getStat(path) {
        return new Promise((resolve, reject) => {
            const trxn = this.db.transaction(["fileMetadata"], "readonly");
            const fileMetadata = trxn.objectStore("fileMetadata");
            const request = fileMetadata.get(path);
            request.onerror = (err) => {
                console.error(`Request to get file metadata for '${path}' failed.`);
                reject(err);
            };
            request.onsuccess = (event) => {
                resolve(request.result);
            };
        });
    }

    async getFile(path) {
        return new Promise((resolve, reject) => {
            const trxn = this.db.transaction(["files"], "readonly");
            const files = trxn.objectStore("files");
            const request = files.get(path);
            request.onerror = (err) => {
                console.error(`Request to get file for '${path}' failed.`);
                reject(err);
            };
            request.onsuccess = (event) => {
                if (request.result && request.result.content) {
                    return resolve(request.result.content);
                }
                return resolve(undefined);
            };
        });
    }

    async putFile(filepath, content) {
        const name = path.basename(filepath);
        const mtime = new Date();
        const metadata = {
            name,
            path: filepath,
            modified: mtime,
            accessed: mtime,
            rev: '',
            size: content.length
        };
        const trxn = this.db.transaction(["fileMetadata", "files"], "readwrite");
        const fileMetadata = trxn.objectStore("fileMetadata");
        const files = trxn.objectStore("files");
        const writeMetadataRequest = fileMetadata.put(metadata);
        const writeMetadataPromise = new Promise((resolve, reject) => {
            writeMetadataRequest.onerror = (err) => {
                console.error(`Failed to write metadata for '${metadata.path}'`);
                reject(err);
            };
            writeMetadataRequest.onsuccess = (event) => {
                resolve(writeMetadataRequest.result);
            };
        });
        const writeContentRequest = files.put({
            path: metadata.path,
            content: content,
        });
        const writeContentPromise = new Promise((resolve, reject) => {
            writeContentRequest.onerror = (err) => {
                console.error(`Request to get file metadata for '${path}' failed.`);
                reject(err);
            };
            writeContentRequest.onsuccess = (event) => {
                resolve(writeContentRequest.result);
            };
        });
        return Promise.all([writeContentPromise, writeMetadataPromise]);
    }

    async insertNote(note) {
        if (!(note && note.title && note.tags && note.uuid && note.content)) {
            throw new Error('Invalid note object');
        }

        return new Promise((resolve, reject) => {
            const trxn = this.db.transaction(["notes"], "readwrite");

            trxn.oncomplete = (event) => {
                // Transaction complete....
                // app.ports.dbSub.send("Category added...");
                console.log('insertNote transaction complete.');
                resolve();
            };

            trxn.onerror = (err) => {
                console.error('insertNote transaction error', err);
                reject(err);
            };

            const notes = trxn.objectStore("notes");
            const request = notes.add(note);
            request.onsuccess = (event) => {
                // resolve?
                console.log(`Add note success.`);
            };
        });
    }

    async clearDatabase() {
        return new Promise((resolve, reject) => {
            const trxn = this.db.transaction(["notes", "files", "fileMetadata"], "readwrite");

            trxn.oncomplete = (event) => {
                console.log(`Database cleared.`);
                resolve();
            };

            trxn.onerror = (err) => {
                console.log(`Error clearing database: ${err}`);
                reject(err);
            };

            const notes = trxn.objectStore("notes");
            const files = trxn.objectStore("files");
            const fileMetadata = trxn.objectStore("fileMetadata");
            notes.clear();
            files.clear();
            fileMetadata.clear();
        });
    }
}
