"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.sync = exports.SyncResult = void 0;
/**
 * Synchronization results. TODO Union type to separate success and failure? Result type? Do we want
 * to return information about skipped files?
 */
var SyncResult = /** @class */ (function () {
    function SyncResult() {
    }
    return SyncResult;
}());
exports.SyncResult = SyncResult;
/**
 * Synchronize a set of files in two storage locations.
 */
function sync(remoteStorage, localStorage, options) {
    return __awaiter(this, void 0, void 0, function () {
        var syncTime, remoteFiles, syncPromises, count, _loop_1, _i, remoteFiles_1, remoteFile, syncResult;
        var _this = this;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    syncTime = Date.now();
                    return [4 /*yield*/, remoteStorage.listFiles()];
                case 1:
                    remoteFiles = _a.sent();
                    syncPromises = [];
                    // TODO can we download a zip file instead? Lots of latency downloading all these files individually.
                    console.log("Syncing " + remoteFiles.length + " files...");
                    count = 0;
                    _loop_1 = function (remoteFile) {
                        var localFile, syncPromise;
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0: return [4 /*yield*/, localStorage.getStat(remoteFile.path)];
                                case 1:
                                    localFile = _a.sent();
                                    if (!localFile || localFile.modified < remoteFile.modified) {
                                        syncPromise = new Promise(function (resolve) { return __awaiter(_this, void 0, void 0, function () {
                                            var result;
                                            return __generator(this, function (_a) {
                                                switch (_a.label) {
                                                    case 0:
                                                        console.log("Syncing " + remoteFile.path);
                                                        return [4 /*yield*/, syncFile(remoteStorage, localStorage, remoteFile)];
                                                    case 1:
                                                        result = _a.sent();
                                                        count++;
                                                        // grr
                                                        if (options && options.progressCallback) {
                                                            options.progressCallback(Math.round(count / remoteFiles.length * 100));
                                                        }
                                                        if (options && options.localfileUpdateCallback && result.localFileMetadata) {
                                                            options.localfileUpdateCallback(result.localFileMetadata);
                                                        }
                                                        resolve(result);
                                                        return [2 /*return*/];
                                                }
                                            });
                                        }); });
                                        syncPromises.push(syncPromise);
                                    }
                                    else {
                                        //console.log(`Skipping '${remoteFile.path}', already downloaded.`);
                                        count++;
                                    }
                                    return [2 /*return*/];
                            }
                        });
                    };
                    _i = 0, remoteFiles_1 = remoteFiles;
                    _a.label = 2;
                case 2:
                    if (!(_i < remoteFiles_1.length)) return [3 /*break*/, 5];
                    remoteFile = remoteFiles_1[_i];
                    return [5 /*yield**/, _loop_1(remoteFile)];
                case 3:
                    _a.sent();
                    _a.label = 4;
                case 4:
                    _i++;
                    return [3 /*break*/, 2];
                case 5:
                    console.log("Waiting for " + syncPromises.length + " to complete");
                    return [4 /*yield*/, Promise.all(syncPromises)];
                case 6:
                    syncResult = _a.sent();
                    console.log("Complete.");
                    // 3) Check for local files that are not remote. Were they deleted on remote, or added locally?
                    // Do this by querying for files in the local db with a syncTime older than the current syncTime.
                    // 4) Resolve conflicts somehow... maybe leave this to the UI.
                    return [2 /*return*/, syncResult];
            }
        });
    });
}
exports.sync = sync;
function syncFile(remoteStorage, localStorage, remoteFile) {
    return __awaiter(this, void 0, void 0, function () {
        var result, content, localFile, err_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    result = {
                        remoteFileMetadata: remoteFile,
                    };
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 6, , 7]);
                    return [4 /*yield*/, remoteStorage.getFile(remoteFile.path)];
                case 2:
                    content = _a.sent();
                    if (!content) return [3 /*break*/, 5];
                    return [4 /*yield*/, localStorage.putFile(remoteFile.path, content)];
                case 3:
                    _a.sent();
                    return [4 /*yield*/, localStorage.getStat(remoteFile.path)];
                case 4:
                    localFile = _a.sent();
                    if (localFile) {
                        result.localFileMetadata = localFile;
                    }
                    _a.label = 5;
                case 5: return [3 /*break*/, 7];
                case 6:
                    err_1 = _a.sent();
                    result.errorMessage = "Failed to retrieve '" + remoteFile.name + "'";
                    console.log("Failed to retrieve '" + remoteFile.path + "', err: " + err_1);
                    return [3 /*break*/, 7];
                case 7: return [2 /*return*/, result];
            }
        });
    });
}
